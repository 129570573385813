import styled from '@emotion/styled'
import {
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'

const styleds = {
  Sidebar: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 8px;
    padding: 8px 0 0 0;
    position: sticky;
    top: 0;
    background-color: #181a20;
    border-right: 1px solid #777777;
  `,
  Column: styled.div`
    display: flex;
    flex-direction: column;
  `,
  PageMenuBox: styled.div`
    display: flex;
    flex-direction: column;
    height: calc(50% - 80px);
  `,
  PageMenuButton: styled.div<{ active: boolean }>`
    ${fill_vertical_all_center};
    border-radius: 4px;
    background-color: #2d303e;
    border: 1px solid ${options => (options.active === true ? '#aaaaaa' : '#2e303daa')};
    cursor: pointer;
    &:hover {
      background-color: #3e414e;
    }
  `,
  PageMenuSymbolList: styled.div`
    ${fill_vertical_cross_center}
    justify-content: start;
    padding: 4px;
    gap: 8px;
    height: 50%;
  `,
}

export default styleds
