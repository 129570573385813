import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { memo, useEffect, useMemo } from 'react'
import { useSnapshot } from 'valtio'
import {
  fill_horizontal_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { useWeightedStocksPoolState } from '~/modules/monitors/useWeightedStocksPoolState'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import { processQuoteToReadable } from '~/utils/processQuoteToReadable'
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2'
import { useSymbolStore } from '~/modules/symbolQuote/simple/useSymbolStore'
import { useMount } from 'react-use'
import { useStockWeightResource } from '~/modules/chips/useChipResource'
import { useTw50StockList } from '~/modules/SDK/Symbol/useCommonSymbolList'

export type WeightedStockRatioType = {
  symbol: string
  close: number
  change: number
  changePercent: number
  weight: number
  prevRef: number
  contribution: number
  contributionAmp: number
}

/** 可以返回權值股的資料 */
export const useWeightedStockRatio = () => {
  //-----------------------------------------------
  const twse = useStockWeightResource('TWSE')
  const twse50 = useTw50StockList()
  const orderedTWSEStocks = useMemo(() => {
    return twse
      .filter(([k]) => twse50?.indexOf(k) !== -1)
      .map(([k, v]) => ({ symbol: k, percentage: v.percentage }))
      .sort((a, b) => b.percentage - a.percentage)
  }, [twse, twse50])

  const getWeight = (symbol: string | undefined) =>
    0.01 * (orderedTWSEStocks.find(s => s.symbol === symbol)?.percentage ?? 0.01)
  //-----------------------------------------------

  //與加權指數報價合併,不然會算不出貢獻點數
  const allSymbol = orderedTWSEStocks.map(s => s.symbol).concat(['TSEA'])

  useEffect(() => {
    signalrStore2.addQuote(allSymbol)
    return () => {
      signalrStore2.removeQuote(allSymbol)
    }
  }, [JSON.stringify(allSymbol)])

  const indexValue = useSnapshot(signalrStore2.values.quote)['TSEA']
  const indexClose = indexValue?.close ?? 0
  const value = signalrHooks2.useQuotes(allSymbol)

  const rowData = () => {
    return value.map(data => {
      const quoteChanges = processQuoteToReadable(data as Signalr.ValueOfOHLC)
      const close = data?.close ?? 0
      const high = data?.high ?? 0
      const low = data?.low ?? 0
      const prevRef = data?.prevRef ?? 0
      const change = close - prevRef
      const symbol = data?.symbol
      const weight = getWeight(symbol)
      const changePercent = quoteChanges.closeChangePercent ?? 0
      const amplitude = high - low
      const indexChanged = (indexClose * getWeight(symbol)) / close
      const contributionPoints = parseFloat((indexChanged * change).toFixed(2))
      const contributionAmplitude = parseFloat((indexChanged * amplitude).toFixed(2))

      return {
        symbol: symbol,
        close: close,
        change: change,
        changePercent: changePercent,
        weight: weight * 100,
        prevRef: prevRef,
        contribution: contributionPoints,
        contributionAmp: contributionAmplitude,
      } as WeightedStockRatioType
    })
  }

  //將加權指數本身移除
  return rowData().filter(a => a.symbol !== 'TSEA')
}

/** 實驗性的 權值股資料清單 */
export const WeightedStockRatioList = memo<ReactProps>(function WeightedStockRatioList() {
  const stockData = useWeightedStockRatio()

  useMount(() => {
    useSymbolStore.currentSymbol = '2330'
  })

  return (
    <div
      css={css`
        ${fill_vertical_cross_center};
        min-width: 600px;
      `}
    >
      {stockData.map((data, index) => {
        return (
          <div
            key={index}
            onClick={() => (useSymbolStore.currentSymbol = data.symbol)}
            css={css`
              ${fill_vertical_cross_center};
              gap: 4px;
            `}
          >
            {<WeightedStockRatioItem data={data} />}
          </div>
        )
      })}
    </div>
  )
})

//代碼 名稱 收盤 漲跌幅 權重 分數 作收
export const StockScoreListHeader = memo<ReactProps>(function StockScoreListHeader() {
  return (
    <div
      css={css`
        ${fill_horizontal_all_center}
        min-width: 600px;
        height: 32px;
        background-color: #222531;
        border-radius: 5px;
      `}
    >
      <styleds.Item>代碼</styleds.Item>
      <styleds.Item>商品</styleds.Item>
      <styleds.Item>收盤</styleds.Item>
      <styleds.Item>漲跌</styleds.Item>
      <styleds.Item>權重</styleds.Item>
      <styleds.Item>參考</styleds.Item>
      <styleds.Item>分數</styleds.Item>
    </div>
  )
})

export const WeightedStockRatioItem = memo<ReactProps<{ data: WeightedStockRatioType }>>(
  function WeightedStockRatioItem(props) {
    const negative = (value: number) => {
      if (value > 0) {
        return '+'
      } else return ''
    }
    const state = useSnapshot(useSymbolStore)
    const isActive = state.currentSymbol === props.data.symbol
    const activeColor = isActive === true ? '#272b3b' : '#181a1f'

    return (
      <div
        css={css`
          ${fill_horizontal_all_center};
          background-color: ${activeColor};
          border: 0.6px solid ${activeColor};
          border-radius: 5px;
          &:hover {
            background-color: #23253088;
            border: 0.6px solid #555555;
          }
        `}
      >
        <styleds.Item>{props.data.symbol}</styleds.Item>
        <styleds.Item>
          <SymbolName symbol={props.data.symbol} />
        </styleds.Item>
        <styleds.Item
          value={props.data.changePercent}
          type='quote'
        >
          {props.data.close}
        </styleds.Item>
        <styleds.Item
          value={props.data.changePercent}
          type='quote'
        >
          {negative(props.data.changePercent)}
          {props.data.changePercent}
        </styleds.Item>
        <styleds.Item>{props.data.weight.toFixed(2)}%</styleds.Item>
        <styleds.Item>{props.data.prevRef.toFixed(2)}</styleds.Item>
        <styleds.Item
          value={props.data.contribution}
          type='score'
        >
          {negative(props.data.contribution)}
          {props.data.contribution.toFixed(2)}
        </styleds.Item>
      </div>
    )
  },
)

const styleds = {
  Item: styled.div<{ type?: 'score' | 'quote'; value?: number }>`
    ${fill_horizontal_all_center}
    width: 100%;
    height: 32px;
    border-radius: 10px;
    color: #eeeeee;
    cursor: pointer;

    ${options => {
      const value = options.value ?? 0
      const color = isNaN(value) || value === 0 ? '' : value > 0 ? '#ff0031cc' : '#00cc31cc'
      const bgcolor =
        isNaN(value) || value === 0 ? '#2d303e' : value > 0 ? '#ff0031cc' : '#00cc31cc'

      const quoteType =
        options.type === 'quote' &&
        css`
          color: ${color};
        `
      const scoreType =
        options.type === 'score' &&
        css`
          background-color: ${bgcolor};
        `
      return css([quoteType, scoreType])
    }}
  `,
}
