import { css } from '@emotion/react'
import React, { memo } from 'react'
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Cell,
  Tooltip,
  XAxis,
  ReferenceLine,
  CartesianGrid,
  YAxis,
  XAxisProps,
  YAxisProps,
} from 'recharts-new'
import { toReadableUnitChinese } from '~/utils/representChineseNumberFormat'

/** 近N日籌碼每日增減圖表 (rechart圖表) */
export const ChipsChart = memo<
  ReactProps<{
    data: { value: number; date: string }[]
  }>
>(function ChipsChart(props) {
  //為了增加判讀容易度 將座標高低都一樣的高度 0軸維持中間
  const maxValue = Math.max(...props.data.map(s => Math.abs(s.value))) * 1.05
  const domainRange = [maxValue, -maxValue]
  const priceRange = [maxValue, -maxValue]

  const fontSize = 10
  const xAxisProps: XAxisProps = {
    tick: { fontSize: fontSize, fill: '#ffffff' },
  }
  const yAxisProps: YAxisProps = { tick: { fontSize: fontSize, fill: '#ffffff' } }

  return (
    <ResponsiveContainer
      width='100%'
      height='100%'
    >
      <BarChart
        data={props.data}
        margin={{
          bottom: -12,
          left: 20,
        }}
      >
        <CartesianGrid
          strokeLinejoin='round'
          stroke='#dddddd'
          strokeOpacity={0}
          strokeDasharray='2 2'
          //fill='#2d303e'
        />
        <Tooltip formatter={(value: number) => toReadableUnitChinese(value)} />
        <Bar
          dataKey='value'
          name={'增減'}
        >
          {props.data.map((datum, index) => (
            <Cell
              key={index}
              fill={datum.value >= 0 ? '#d32f2f' : '#4caf50'}
            />
          ))}
        </Bar>
        <ReferenceLine
          y={0}
          stroke='#aaaaaa'
        />
        <XAxis
          dataKey='date'
          name={'日期'}
          axisLine={false}
          tickLine={false}
          {...xAxisProps}
        />
        <YAxis
          domain={domainRange}
          ticks={priceRange}
          hide={true}
          fill='#ffffff'
          {...yAxisProps}
        />
      </BarChart>
    </ResponsiveContainer>
  )
})
