import { css } from '@emotion/react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import col_UserAvatarAndChartingServers from '~/pages/heineken_template/_col/col_UserAvatarAndChartingServers'
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar'
import { Charting } from '~/pages/heineken_template/_private/Charting'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { sungop_initStyling } from '~/pages/sungop/_private/sungop_initStyling'
import { Sungop_PageMenu } from '~/pages/sungop/_private/sungop_PageMenu'
import { Sungop_Sidebar } from '~/pages/sungop/_private/sungop_Sidebar'
import { Table } from '~/pages/sungop/_private/component/Table'
import { Sungop_DailyChips } from './sungop_DailyChips'
import { Sungop_stockMonitor } from './sungop_stockMonitor'
import { Sungop_OptionState } from './sunop_OptionState'
import { sungop_initStrategies } from './sungop_initStrategies'

export const sungop_init = {
  global(templateProps: TemplateProps) {
    sungop_initStyling(templateProps)

    useThemeStore.setState({ theme: 'dark' })
    store.charting.setThemeMode('dark')

    templateProps.layout.Charting = undefined

    templateProps.layout.Drawer1 = Sungop_PageMenu

    templateProps.layout.Row1 = (
      <Preset_Topbar
        hideIfPcView
        showLeftBurger
        componentsInRight={col_UserAvatarAndChartingServers.Display}
      />
    )
  },
  indexPage(templateProps: TemplateProps) {
    sungop_init.global(templateProps)
    sungop_initStrategies()

    templateProps.layout.Charting = Charting

    templateProps.layout.Col1 = Sungop_Sidebar

    templateProps.layout.cssset = css`
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: 200px 336px 1fr;

      grid-template-areas:
        'Drawer1 Col1 Chart'
        'Drawer1 Col1 Chart'
        'Drawer1 Col1 Chart';

      overflow: auto;
      ${createIPadCss(css`
        place-content: flex-start;
        grid-template-rows: 48px 1fr calc(100% - 48px);
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col1 Col1 Col1'
          'Chart Chart Chart';
      `)}

      ${createMobileCss2(css`
        place-content: flex-start;
        grid-template-rows: 48px 1fr calc(100% - 48px);
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col1 Col1 Col1'
          'Chart Chart Chart';
      `)}
    `

    store.charting.widgetOptions = {
      ...store.charting.widgetOptions,
      symbol: 'TX-1',
      interval: '5',
    }
  },
  ['monitors/stock/index.page'](templateProps: TemplateProps) {
    sungop_init.global(templateProps)

    templateProps.layout.Col2 = Sungop_stockMonitor

    templateProps.layout.cssset = css`
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 200px 1fr;
      overflow: auto;

      grid-template-areas:
        'Drawer1 Col2'
        'Drawer1 Col2';

      ${createIPadCss(css`
        place-content: flex-start;
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          'Row1 Row1'
          'Col2 Col2';
      `)}

      ${createMobileCss2(css`
        place-content: flex-start;
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          'Row1 Row1'
          'Col2 Col2';
      `)}
    `
  },
  ['stock-monitor-table/index.page'](templateProps: TemplateProps) {
    sungop_init.global(templateProps)

    templateProps.layout.Col2 = Sungop_stockMonitor

    templateProps.layout.cssset = css`
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 200px 1fr;
      overflow: auto;

      place-content: flex-start;

      grid-template-areas:
        'Drawer1 Col2 Col2'
        'Drawer1 Col2 Col2';

      ${createIPadCss(css`
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          'Row1 Row1'
          'Col2 Col2';
      `)}

      ${createMobileCss2(css`
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1'
          'Col2 Col2'
          'Col2 Col2';
      `)}
    `
  },
  ['daily-chips/index.page'](templateProps: TemplateProps) {
    sungop_init.global(templateProps)

    templateProps.layout.Col2 = Sungop_DailyChips

    templateProps.layout.cssset = css`
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 200px 1fr;
      overflow: auto;

      place-content: flex-start;

      grid-template-areas:
        'Drawer1 Col2 Col2'
        'Drawer1 Col2 Col2';

      ${createIPadCss(css`
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          'Row1 Row1'
          'Col2 Col2';
      `)}

      ${createMobileCss2(css`
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1'
          'Col2 Col2'
          'Col2 Col2';
      `)}
    `
  },
  ['option-state/index.page'](templateProps: TemplateProps) {
    sungop_init.global(templateProps)

    templateProps.layout.Col2 = Sungop_OptionState

    templateProps.layout.cssset = css`
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 200px 1fr;
      overflow: auto;

      place-content: flex-start;

      grid-template-areas:
        'Drawer1 Col2 Col2'
        'Drawer1 Col2 Col2';

      ${createIPadCss(css`
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          'Row1 Row1'
          'Col2 Col2';
      `)}

      ${createMobileCss2(css`
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1'
          'Col2 Col2'
          'Col2 Col2';
      `)}
    `
  },
}
