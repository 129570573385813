import { css } from '@emotion/react'
import { memo } from 'react'
import { scrollbar2Css } from '~/css/scrollbarCss'
import { useUnifyTableResource } from '~/modules/chips/useChipResource'
import dayAPI from '~/utils/dayAPI'
import { ChipOverViewBoard } from './component/ChipOverViewBoard'

export const Sungop_DailyChips = memo<ReactProps>(function Sungop_DailyChips() {
  //日期轉換
  const todayDate = new Date()
  const startUnix = new Date().setDate(todayDate.getDate() - 30)
  const startDate = new Date(startUnix)
  const todayURL = Math.floor(todayDate.setHours(15, 5, 0) / 1000)
  const startURL = Math.floor(startDate.setHours(15, 0, 0) / 1000)

  const chips = useUnifyTableResource(startURL, todayURL)

  //當日日期
  const chipsDate = chips?.t[chips?.t.length - 1] ?? todayURL
  const chipsDateToString = dayAPI(chipsDate * 1000).format('YYYY/MM/DD')

  //陣列長度
  const dataDate = chips?.t
  //外資系列
  const foreignerTWSE = chips?.foreigner_twse
  const foreignerTPEX = chips?.foreigner_tpex
  const foreignerTXF = chips?.foreigner_txf
  const foreignerOPT = chips?.foreigner_opt

  //自營商系列
  const dealerTWSE = chips?.dealer_twse
  const dealerTPEX = chips?.dealer_tpex
  const dealerTXF = chips?.dealer_txf
  const dealerOPT = chips?.dealer_opt

  //投信系列
  const trustTWSE = chips?.trust_twse
  const trustTPEX = chips?.trust_tpex

  //大額交易人(台指期貨)系列
  const largeTop5TXF = chips?.large_trader_futures_top5
  const largeLast5TXF = chips?.large_trader_futures_last5
  const largeTop10TXF = chips?.large_trader_futures_top10
  //大額交易人(選擇權留倉)系列
  const largeTop5OPT = chips?.large_trader_options_top5
  const largeLast5OPT = chips?.large_trader_options_last5
  const largeTop10OPT = chips?.large_trader_options_top10

  //散戶小型台指系列
  const retailMTF = chips?.retail_oi

  const twseArray = [
    {
      title: '外資',
      unit: '億',
      date: dataDate,
      value: foreignerTWSE?.trading_value_diff,
      dataLenght: 10,
    },
    {
      title: '自營',
      unit: '億',
      date: dataDate,
      value: dealerTWSE?.trading_value_diff,
      dataLenght: 10,
    },
    {
      title: '投信',
      unit: '億',
      date: dataDate,
      value: trustTWSE?.trading_value_diff,
      dataLenght: 10,
    },
  ]

  const tpexArray = [
    {
      title: '外資',
      unit: '億',
      date: dataDate,
      value: foreignerTPEX?.trading_value_diff,
      dataLenght: 10,
    },
    {
      title: '自營',
      unit: '億',
      date: dataDate,
      value: dealerTPEX?.trading_value_diff,
      dataLenght: 10,
    },
    {
      title: '投信',
      unit: '億',
      date: dataDate,
      value: trustTPEX?.trading_value_diff,
      dataLenght: 10,
    },
  ]

  const TXFArray = [
    {
      title: '外資',
      unit: '口',
      date: dataDate,
      value: foreignerTXF?.oi_diff,
      dataLenght: 10,
    },
    {
      title: '自營',
      unit: '口',
      date: dataDate,
      value: dealerTXF?.oi_diff,
      dataLenght: 10,
    },
    {
      title: '散戶(小台)',
      unit: '口',
      date: dataDate,
      value: retailMTF?.oi_diff,
      dataLenght: 10,
    },
  ]

  const optArray = [
    {
      title: '外資',
      unit: '口',
      date: dataDate,
      value: foreignerOPT?.oi_volume_diff,
      dataLenght: 10,
    },
    {
      title: '自營',
      unit: '口',
      date: dataDate,
      value: dealerOPT?.oi_volume_diff,
      dataLenght: 10,
    },
  ]
  const top5Array = [
    {
      title: '前五大',
      unit: '口',
      date: dataDate,
      value: largeTop5TXF?.oi_diff,
      dataLenght: 10,
    },
    {
      title: '前十大',
      unit: '口',
      date: dataDate,
      value: largeTop10TXF?.oi_diff,
      dataLenght: 10,
    },
    {
      title: '後五大',
      unit: '口',
      date: dataDate,
      value: largeLast5TXF?.oi_diff,
      dataLenght: 10,
    },
  ]

  const top5OpArray = [
    {
      title: '前五大',
      unit: '口',
      date: dataDate,
      value: largeTop5OPT?.oi_diff,
      dataLenght: 10,
    },
    {
      title: '前十大',
      unit: '口',
      date: dataDate,
      value: largeTop10OPT?.oi_diff,
      dataLenght: 10,
    },
    {
      title: '後五大',
      unit: '口',
      date: dataDate,
      value: largeLast5OPT?.oi_diff,
      dataLenght: 10,
    },
  ]

  return (
    <div
      css={css`
        padding: 16px;
        background-color: #181a1f;
        gap: 8px;
        ${scrollbar2Css};
      `}
    >
      <div>每日籌碼概覽 更新日期:{chipsDateToString}</div>
      <div
        css={css`
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin: 0 auto;
          gap: 16px;
          justify-content: center;
        `}
      >
        <ChipOverViewBoard data={twseArray}>現貨買賣超(上市)</ChipOverViewBoard>
        <ChipOverViewBoard data={tpexArray}>現貨買賣超(上櫃)</ChipOverViewBoard>
        <ChipOverViewBoard data={TXFArray}>台指期貨(日增減口數)</ChipOverViewBoard>
        <ChipOverViewBoard data={top5Array}>期貨大額交易人(日增減口數)</ChipOverViewBoard>
        <ChipOverViewBoard
          data={optArray}
          bgColor='#222831'
        >
          台指選擇權(日增減口數)
        </ChipOverViewBoard>
        <ChipOverViewBoard
          data={top5OpArray}
          bgColor='#222831'
        >
          選擇權大額交易人(日增減口數)
        </ChipOverViewBoard>
      </div>
    </div>
  )
})
