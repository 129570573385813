import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'

export const op_orb = createIndicator({
  displayName: 'ORB',
  id: 'op-orb',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const sessionType = SessionType.TAIFEX_AM
      const dateTime = new Date(this.PineJS.Std.time(this._context))
      const high = this.ohlc.highArray
      const low = this.ohlc.lowArray
      const close = this.ohlc.closeArray
      const open = this.ohlc.openArray
      const flag = this._context.new_var()

      high.get(10)
      low.get(10)
      close.get(10)
      // -------------------------------------- Indicators --------------------------------------
      const hansHigh = this._context.new_var()
      const hansLow = this._context.new_var()

      const dayHigh = this._context.new_var()
      const dayLow = this._context.new_var()

      // Reset high low at first bar
      if (this.isSessionFirstBar(sessionType)) {
        hansHigh.set(0)
        hansLow.set(0)
        dayHigh.set(high.get(0))
        dayLow.set(low.get(0))
      } else if (dateTime.getHours() === 9 && dateTime.getMinutes() === 0) {
        hansHigh.set(dayHigh.get(0))
        hansLow.set(dayLow.get(0))
      } else if (dateTime.getHours() < 14) {
        dayHigh.set(this.maxList([high.get(0), dayHigh.get(0)]))
        dayLow.set(this.minList([low.get(0), dayLow.get(0)]))
      }

      // -------------------------------------- Entries --------------------------------------
      let longFlag = 0
      let shortFlag = 0
      const breakoutTimeFilter = dateTime.getHours() >= 9 && dateTime.getHours() <= 14

      if (close.get(0) > hansHigh.get(0) && hansHigh.get(0) !== 0 && breakoutTimeFilter) {
        longFlag = 1
        flag.set(1)
      } else if (close.get(0) < hansLow.get(0) && hansLow.get(0) !== 0 && breakoutTimeFilter) {
        shortFlag = -1
        flag.set(-1)
      } else {
        flag.set(0)
      }

      // -------------------------------------- Exits --------------------------------------

      let longDangerFlag = 0
      let shortDangerFlag = 0

      const timeFilter1 = dateTime.getHours() === 9 && dateTime.getMinutes() >= 30
      const timeFilter2 = dateTime.getHours() >= 10 && dateTime.getHours() <= 14
      const reverseTimeFilter = timeFilter1 || timeFilter2

      if (
        high.get(0) === dayHigh.get(0) &&
        close.get(0) <= this.lowest(low, 3) &&
        reverseTimeFilter &&
        hansHigh.get(0) !== 0
      ) {
        longDangerFlag = 1.5
        longFlag = 0
        flag.set(0)
      }

      if (
        low.get(0) === dayLow.get(0) &&
        close.get(0) >= this.highest(high, 3) &&
        reverseTimeFilter &&
        hansLow.get(0) !== 0
      ) {
        shortDangerFlag = -1.5
        shortFlag = 0
        flag.set(0)
      }

      const displayColor = () => {
        if (flag.get(0) === 1) {
          return 0
        } else if (flag.get(0) === -1) {
          return 1
        } else return 2
      }

      return [1, displayColor()]
    },
  },
  metainfo: {
    is_price_study: !1,
    _metainfoVersion: 42,
    isTVScript: !1,
    isTVScriptStub: !1,
    is_hidden_study: !1,
    plots: [
      { id: 'plot_0', type: 'line' },
      {
        id: 'powerPalette',
        type: 'colorer',
        target: 'plot_0',
        palette: 'powerPalette',
      },
    ],
    palettes: {
      powerPalette: {
        colors: {
          0: {
            name: '多方趨勢',
          },
          1: {
            name: '空方趨勢',
          },
          2: {
            name: '盤整趨勢',
          },
        },
      },
    },
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 5,
          plottype: 4,
          trackPrice: !1,
          transparency: 30,
          visible: !0,
          color: '#d75442',
        },
      },

      palettes: {
        powerPalette: {
          colors: {
            0: {
              color: '#da5441',
              width: 0,
              style: 0,
            },
            1: {
              color: '#00980a',
              width: 0,
              style: 0,
            },
            2: {
              color: '#5D616E',
              width: 0,
              style: 0,
            },
          },
        },
      },
      precision: 2,
      inputs: {},
    },
    styles: {
      plot_0: {
        title: '指標',
        histogramBase: 0,
        joinPoints: !1,
      },
    },
    inputs: [],
  },
})
